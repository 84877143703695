var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticStyle:{"max-width":"450px"},attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-y":"","id":"menu"},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[(_vm.selectedEvent)?_c('v-card',{staticClass:"ma-0",style:(_vm.$vuetify.breakpoint.xsOnly
        ? 'min-width: 150px; max-width:350px; border: 1px solid ' +
          _vm.selectedEvent.tattooer.color
        : 'min-width: 350px; max-width:450px; border: 1px solid ' +
          _vm.selectedEvent.tattooer.color),attrs:{"flat":"","id":"eventView"}},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_c('v-icon',{staticClass:"mb-1 mr-2",attrs:{"size":"14","color":_vm.selectedEvent.tattooer.color}},[_vm._v("mdi-circle")]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.$store.getters['auth/isStudio']
              ? _vm.$router.push({
                  name: 'tattooerView',
                  params: { id: _vm.selectedEvent.tattooer_id },
                })
              : null}}},[_vm._v(" "+_vm._s(!_vm.tab_day ? _vm.selectedEvent.tattooer.user.first_name : _vm.selectedEvent.appointments ? _vm.selectedEvent.appointments.customer.user.first_name : _vm.selectedEvent.tattooer.user.first_name)+" "+_vm._s(!_vm.tab_day ? _vm.selectedEvent.tattooer.user.last_name : _vm.selectedEvent.appointments ? _vm.selectedEvent.appointments.customer.user.last_name : _vm.selectedEvent.tattooer.user.last_name)+" ")])],1),_c('v-spacer'),[(
            !_vm.selectedEvent.appointments && _vm.$store.getters['auth/isStudio']
          )?_c('v-btn',{staticClass:"mt-1",attrs:{"icon":"","small":""},on:{"click":_vm.importar}},[_c('v-icon',[_vm._v("mdi-swap-vertical")])],1):_vm._e(),(
            !_vm.selectedEvent.appointments && _vm.$store.getters['auth/isStudio']
          )?_c('v-btn',{staticClass:"mt-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.reserva({ reserva: _vm.selectedEvent, action: 'edit' })}}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("$edit")])],1):_vm._e(),_c('v-btn',{staticClass:"mt-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.delReservation(_vm.selectedEvent)}}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("$delete")])],1)],_c('v-btn',{staticStyle:{"position":"absolute","right":"0","top":"-10px"},attrs:{"icon":""},on:{"click":function($event){_vm.selectedOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],2),_c('v-card-text',{staticClass:"py-1"},[_c('p',{staticClass:"mb-2"},[_c('v-row',[_c('v-col',{staticClass:"pt-1 pr-0 pr-md-5 py-0",attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"pr-3",attrs:{"color":"primary","size":"30"}},[_vm._v("$clock")])],1),_c('v-col',{staticClass:"pb-0",staticStyle:{"padding-top":"10px"}},[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.selectedEvent.start_date), "date2digits"))+" | "+_vm._s(_vm.selectedEvent.start_time)+" - "+_vm._s(_vm.selectedEvent.end_time)+" ")])],1)],1),_c('p',{staticClass:"mb-2"},[_c('v-row',[_c('v-col',{staticClass:"pr-5 py-0",attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"pr-3",attrs:{"color":"primary","size":"30"}},[_vm._v("$boxes")])],1),_c('v-col',{staticClass:"pb-0",staticStyle:{"padding-top":"6px"}},[_vm._v(" "+_vm._s(_vm.selectedEvent.box_name || _vm.selectedEvent.boxes.name)+" ")])],1)],1),(_vm.selectedEvent.appointments)?_c('p',[_c('v-row',[_c('v-col',{staticClass:"pr-5 py-0",attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"pr-3 mt-2",attrs:{"color":"primary"}},[_vm._v("mdi-link")])],1),_c('v-col',{staticClass:"pb-0",staticStyle:{"padding-top":"6px"}},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                  name: 'appointmentView',
                  params: { id: _vm.selectedEvent.appointments.id },
                })}}},[_vm._v(_vm._s(_vm.$tc("appointment")))])])],1)],1):_c('p',[_c('v-row',[_c('v-col',{staticClass:"pr-5 py-0",attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"pr-3 mt-2",attrs:{"color":"primary"}},[_vm._v("mdi-link")])],1),_c('v-col',{staticClass:"pb-0",staticStyle:{"padding-top":"6px"}},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                  name: 'tattooerView',
                  params: { id: _vm.selectedEvent.tattooer_id },
                })}}},[_vm._v(_vm._s(_vm.$tc("artist")))])])],1)],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }