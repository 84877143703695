<template>
  <v-menu
    v-model="selectedOpen"
    :close-on-content-click="false"
    :activator="selectedElement"
    offset-y
    id="menu"
    style="max-width: 450px"
  >
    <v-card
      :style="
        $vuetify.breakpoint.xsOnly
          ? 'min-width: 150px; max-width:350px; border: 1px solid ' +
            selectedEvent.tattooer.color
          : 'min-width: 350px; max-width:450px; border: 1px solid ' +
            selectedEvent.tattooer.color
      "
      class="ma-0"
      flat
      id="eventView"
      v-if="selectedEvent"
    >
      <v-toolbar dark>
        <v-toolbar-title>
          <v-icon
            class="mb-1 mr-2"
            size="14"
            :color="selectedEvent.tattooer.color"
            >mdi-circle</v-icon
          >
          <span
            style="cursor: pointer"
            @click="
              $store.getters['auth/isStudio']
                ? $router.push({
                    name: 'tattooerView',
                    params: { id: selectedEvent.tattooer_id },
                  })
                : null
            "
          >
            {{
              !tab_day
                ? selectedEvent.tattooer.user.first_name
                : selectedEvent.appointments
                ? selectedEvent.appointments.customer.user.first_name
                : selectedEvent.tattooer.user.first_name
            }}
            {{
              !tab_day
                ? selectedEvent.tattooer.user.last_name
                : selectedEvent.appointments
                ? selectedEvent.appointments.customer.user.last_name
                : selectedEvent.tattooer.user.last_name
            }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <template>
          <v-btn
            class="mt-1"
            icon
            @click="importar"
            small
            v-if="
              !selectedEvent.appointments && $store.getters['auth/isStudio']
            "
          >
            <v-icon>mdi-swap-vertical</v-icon>
          </v-btn>
          <v-btn
            icon
            class="mt-1"
            @click="reserva({ reserva: selectedEvent, action: 'edit' })"
            small
            v-if="
              !selectedEvent.appointments && $store.getters['auth/isStudio']
            "
          >
            <v-icon size="15">$edit</v-icon>
          </v-btn>
          <v-btn icon class="mt-1" @click="delReservation(selectedEvent)" small>
            <v-icon size="15">$delete</v-icon>
          </v-btn>
        </template>
        <v-btn
          icon
          @click="selectedOpen = false"
          style="position: absolute; right: 0; top: -10px"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="py-1">
        <p class="mb-2">
          <v-row>
            <v-col cols="1" class="pt-1 pr-0 pr-md-5 py-0">
              <v-icon class="pr-3" color="primary" size="30">$clock</v-icon>
            </v-col>
            <v-col style="padding-top: 10px" class="pb-0">
              {{ $d(new Date(selectedEvent.start_date), "date2digits") }}
              | {{ selectedEvent.start_time }} -
              {{ selectedEvent.end_time }}
            </v-col>
          </v-row>
        </p>
        <p class="mb-2">
          <v-row>
            <v-col cols="1" class="pr-5 py-0">
              <v-icon class="pr-3" color="primary" size="30">$boxes</v-icon>
            </v-col>
            <v-col style="padding-top: 6px" class="pb-0">
              {{ selectedEvent.box_name || selectedEvent.boxes.name }}
            </v-col>
          </v-row>
        </p>
        <p v-if="selectedEvent.appointments">
          <v-row>
            <v-col cols="1" class="pr-5 py-0">
              <v-icon class="pr-3 mt-2" color="primary">mdi-link</v-icon>
            </v-col>
            <v-col style="padding-top: 6px" class="pb-0">
              <a
                @click="
                  $router.push({
                    name: 'appointmentView',
                    params: { id: selectedEvent.appointments.id },
                  })
                "
                >{{ $tc("appointment") }}</a
              >
            </v-col>
          </v-row>
        </p>
        <p v-else>
          <v-row>
            <v-col cols="1" class="pr-5 py-0">
              <v-icon class="pr-3 mt-2" color="primary">mdi-link</v-icon>
            </v-col>
            <v-col style="padding-top: 6px" class="pb-0">
              <a
                @click="
                  $router.push({
                    name: 'tattooerView',
                    params: { id: selectedEvent.tattooer_id },
                  })
                "
                >{{ $tc("artist") }}</a
              >
            </v-col>
          </v-row>
        </p>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "ReservationCard",
  mounted() {
    this.selectedOpen = false;
  },
  computed: {
    ...mapState("boxes", [
      "selectedEventState",
      "selectedElementState",
      "selectedOpenState",
    ]),
    selectedEvent: {
      get() {
        return this.selectedEventState;
      },
      set(value) {
        this.$store.commit("boxes/SET_SELECTED_EVENT", value);
      },
    },
    selectedElement: {
      get() {
        return this.selectedElementState;
      },
      set(value) {
        this.$store.commit("boxes/SET_SELECTED_ELEMENT", value);
      },
    },
    selectedOpen: {
      get() {
        return this.selectedOpenState;
      },
      set(value) {
        this.$store.commit("boxes/SET_SELECTED_OPEN", value);
      },
    },
  },
  methods: {
    ...mapMutations("boxes", ["reserva"]),
    ...mapActions("boxes", ["importToAppointment"]),
    ...mapActions("tattooers", ["reservationBoxDelete"]),
    delReservation(value) {
      this.$confirm(
        value.appointments
          ? this.$t("are_you_sure_reserva_box")
          : this.$t("are_you_sure_reserva"),
        "",
        ""
      ).then(() => {
        this.id_reserva = value.reserva_id;
        //console.log(this.id_reserva);
        this.reservationBoxDelete({
          id_reserva: this.id_reserva,
        }).then((response) => {
          if (response) {
            this.errors.clear();
            this.$alert(this.$t("reserva_delete_success"), "", "");
            this.$emit("reload");
            this.selectedOpen = false;
          }
        });
      });
    },
    importar() {
      this.$confirm(
        "¿Quieres transformar esta reserva de box en una cita?"
      ).then(() => {
        let formData = {
          id: this.selectedEvent.reserva_id,
        };
        this.importToAppointment(formData).then(() => {
          this.$emit("reload");
          this.selectedOpen = false;
        });
      });
    },
  },
};
</script>